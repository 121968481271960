import React from "react"
import telecomimage from "../../assets/images/banners/industry-images/telecom.jpg"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"
const Telecommunication = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img telecom"
          data-bg=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">
                    Telecommunication Solutions
                  </h1>
                  <h5 className="font-weight--normal text-white">
                    We design, implement and evaluate market demands to add more
                    value.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our Industry Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Telecommunication <span className="text-color-primary">Solutions</span></h2>
                  Aamanto enables telecommunications organizations in
                  adapting old infrastructure to changing market
                  demands, accelerate digital transformation, add
                  competitive value to their products, and generate new
                  sources of revenue. We engage with communications
                  service providers to improve software-driven network
                  services by digitizing their internal systems and
                  procedures. As a result, we assist satellite
                  operators, traditional telecoms, OTT and media
                  providers, and infrastructure operators in staying
                  current with new technologies and implementing
                  business models.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={telecomimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

             <!--========= About Delivering Wrapper Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">What Services <span className="text-color-primary">We Offer</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Network Cloudification </h5>
                      <div className="desc">
                        {" "}
                        Reduce expenses, increase service elasticity, and
                        simplify VNF onboarding and deployment by implementing
                        the cloud-native network.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Implementation of IoT Solutions{" "}
                      </h5>
                      <div className="desc">
                        Roll out unique IoT platforms, modules, and apps to
                        capitalize on IoT business prospects and achieve a
                        competitive advantage.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Cognitive Computing </h5>
                      <div className="desc">
                        With AI-powered data analytics, you can increase
                        consumer engagement, and streamline network operations.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Modernization of support systems (BSS/OSS)
                      </h5>
                      <div className="desc">
                        Automate your support systems to provide smooth 5G, IoT,
                        and digital service deployment, maintenance, and
                        monetization.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Digital Telecommunications Services{" "}
                      </h5>
                      <div className="desc">
                        Add next-generation digital services to your portfolios,
                        including superfast internet and cloud-native VAS
                        applications.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">VoIP Solutions Engineering </h5>
                      <div className="desc">
                        With our custom-built Unified Communications systems,
                        you may instantly install carrier-grade VoIP services.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== About Company Area End ==========--> */}
      </div>
    </div>
  )
}

export default Telecommunication
