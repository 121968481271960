import * as React from "react"
import Layout from "../components/layout"
import Telecommunication from "../components/Industries/Telecommunication"






const telecommunication = () => {
  return (
  <Layout>
   <Telecommunication/>
  </Layout>
)
};
export default telecommunication;
